body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #262626;
    overscroll-behavior: none;
}

.Mui-selected {
    color: #9aa2ae !important;
}

.MiddlePage {
    background-image: url("./images/homepage/MiddlePage.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 50% 100%;
    background-color: #262626;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-free-mode > .swiper-wrapper{
    transition-timing-function : linear !important;
}
